
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get } from '@/services/http';
import { Redirect } from '@shopify/app-bridge/actions';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';

@Component
export default class UpgradeVersion extends Vue {
    @Prop({default: false})
    public needUpgrade!: boolean;
    public installUrl: string = '';
    @Prop({default: ''})
    public shopName!: string;
    public showLoading: boolean = false;
    @Prop({default: null})
    public app!: any;

    public async upgradeVersion() {
      if (this.needUpgrade) {
        this.showLoading = true;
        const res: any = await get('/shopify/install?shop=' + this.shopName);
        this.installUrl = res.install_url;
        this.showLoading = false;
      }
      if (this.installUrl) {
          this.showLoading = true;
          // If your app is embedded inside Shopify, use App Bridge to redirect
          if (isShopifyEmbedded()) {
            Redirect.create(this.app).dispatch(Redirect.Action.REMOTE, this.installUrl);
            // Otherwise, redirect using the `window` object
          } else {
            window.location.assign(this.installUrl);
          }
          this.installUrl = '';
          this.needUpgrade = false;
      }
    }
}
